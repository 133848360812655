import React, { useState } from 'react';
import { Button, Dropdown, Form, Grid, Header, Loader, Segment } from 'semantic-ui-react';
import helpers, { cancelToken } from '../../services/helpers';
import { useTranslation } from 'react-i18next';
import swfitLogo from './../../assets/img/swift.png';

const TransferForm = (props) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState('');

    const fromAccountOptions = [
        {
            key: 'Test',
            text: 'Test',
            value: 'Test',
        }
    ]

    const currencyOptions = [
        {
            key: 'AUD',
            text: 'AUD',
            value: 'AUD',
        }
    ]

    const countryOptions = [
        {
            key: 'PAPUA NEW GUINEA',
            text: 'PAPUA NEW GUINEA',
            value: 'PAPUA NEW GUINEA',
        },
        {
            key: 'AUSTRALIA',
            text: 'AUSTRALIA',
            value: 'AUSTRALIA',
        },
    ]

    const purposeOfInternationalOptions = [
        {
            key: 'Transfers/Transfer To Offshore A/C',
            text: 'Transfers/Transfer To Offshore A/C',
            value: 'Transfers/Transfer To Offshore A/C',
        }
    ]

    const industryCodeOptions = [
        {
            key: 'Private Individuals And Households',
            text: 'Private Individuals And Households',
            value: 'Private Individuals And Households',
        }
    ]

    const beneficiaryIdOptions = [
        {
            key: 'PASSPORT NO.',
            text: 'PASSPORT NO.',
            value: 'PASSPORT NO.',
        }
    ]

    const handleSubmit = () => {
        var dataTransfer = {
            "sremark": "KBLTT200125014",
            "sacaacno": "001110118517904",
            "saddr1": "LEVEL 3 KINA BANK HAUS",
            "saddr2": "DOUGLAS STREET",
            "saddr5": "PORT MORESBY",
            "sphoneno": "null71007821",
            "sendercntry": "PG",
            "ssource": "SALARY",
            "pursecd": "R13820",
            "indcd": "0000",
            "bfrel": "MYSELF",
            "bfcname": "SUMEET KUMAR",
            "bfcidtyp": "PASSPORTNO",
            "bfcidno": "1012382",
            "bfcaddr1": "Cairns Central",
            "bfcaddr2": "21 McLeod Street",
            "bfccity": "CAIRNS",
            "bfccountry": "AU",
            "bfcphoneno": "null0411369067",
            "bfcemail": "kumar.s.fj@gmail.com",
            "bfcacno": "113681658",
            "bswiftbic": "NATAAU3303M",
            "bfcbnknam": "NATIONAL AUSTRALIA BANK LIMITED",
            "bnkidentifier": "/084472",
            "bbaddr1": "Cairns Central",
            "bbaddr2": "1-21 McLeod Street",
            "bbcity": "CAIRNS",
            "bbcntry": "AU",
            "issfccd": "AUD",
            "trnfccd": "PGK",
            "convamt": 175.00,
            "trnamt": 75.53,
            "exchgrt": 0.4316
            };
        setSubmitting(true)
        const source = cancelToken.source();
        helpers.kinaGeneral('/processoutwardtt', dataTransfer, source)
            .then((response) => {
                setSubmitting(false);
                props.setDataToast({ open: true, type: "info", message:response.data.PO_ERRDESC, timeAutoHide: 6000 });
            })
            .catch((response) => {
                setSubmitting(false);
                props.setDataToast({ open: true, type: "info", message:response.data.PO_ERRDESC, timeAutoHide: 6000 });
            });
    }
    return (
        <>
            {submitting ?
                <Segment>
                    <Loader active size='large' inline='centered'>Loading...</Loader>
                </Segment>
                :
                <Form onSubmit={handleSubmit}>
                    <Header as='h3' style={{ marginTop: 20, lineHeight: '2.5em' }}>
                        {t`kina_bank_transaction_details`}
                        <img src={swfitLogo} alt="Swift" style={{ float:'right', margin: "0", display:'block' }}></img>
                    </Header>
                    <Grid columns={2}>
                        <Grid.Column>
                            <Form.Field >
                                <label>{t`kina_bank_transaction_description`}</label>
                                <input value='KBLTT200123002' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_from_account`}</label>
                                <Dropdown
                                    placeholder='Savings Account 18517904'
                                    fluid
                                    selection
                                    style={{opacity:'initial'}}
                                    options={fromAccountOptions}
                                    disabled={true}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Header as='h3'>{t`kina_bank_sender_details`}</Header>

                    <Grid columns={2}>
                        <Grid.Column>
                            <Form.Field >
                                <label>{t`kina_bank_senders_name`}</label>
                                <input value='SUMEET KUMAR' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_sender_id_type`}</label>
                                <input value='PASSPORT' disabled={true} />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_contact_no`}</label>
                                <input value='71007821' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_sender_id_no`}</label>
                                <input value='1012382' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Grid>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_physical_address`}</label>
                                <input value='LEVEL 3 KINA BANK HAUS' disabled={true} style={{ marginBottom: 5 }} />
                                <input value='DOUGLAS STREET' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Grid columns={3}>
                        <Grid.Column>
                            <Form.Field >
                                <label>{t`kina_bank_city`}</label>
                                <input value='PORT MORESBY' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_source_funds`}</label>
                                <input value='SALARY' disabled={true} />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_country`}</label>
                                <Dropdown
                                    placeholder='Please Select One'
                                    fluid
                                    selection
                                    options={countryOptions}
                                    disabled={true}
                                    value={'PAPUA NEW GUINEA'}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_relationship_beneficiaty`}</label>
                                <input value='MYSELF' disabled={true} />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_purpose_international`}</label>
                                <Dropdown
                                    placeholder='Please Select One'
                                    fluid
                                    selection
                                    options={purposeOfInternationalOptions}
                                    disabled={true}
                                    value={'Transfers/Transfer To Offshore A/C'}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_industry_code`}</label>
                                <Dropdown
                                    placeholder='Please Select One'
                                    fluid
                                    selection
                                    options={industryCodeOptions}
                                    disabled={true}
                                    value={'Private Individuals And Households'}
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Header as='h3'>{t`kina_bank_beneficiary_details`}</Header>
                    <Header as='h3' style={{ paddingBottom: '15px' }}>{t`kina_bank_personal_details`}</Header>

                    <Grid columns={2}>
                        <Grid.Column>
                            <Form.Field >
                                <label>{t`kina_bank_beneficiarys_name`}</label>
                                <input value='SUMEET KUMAR' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_physical_address`}</label>
                                <input value='Cairns Central' disabled={true} style={{ marginBottom: 5 }} />
                                <input value='21 McLeod Street' disabled={true} />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_contact_no`}</label>
                                <Dropdown
                                    placeholder='Please Select One'
                                    fluid
                                    selection
                                    options={beneficiaryIdOptions}
                                    disabled={true}
                                    value={'PASSPORT NO.'}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_beneficiary_id_no`}</label>
                                <input value='1012382' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Grid columns={3}>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_city`}</label>
                                <input value='CAIRNS' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_contact_no`}</label>
                                <input value='0411369067' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_email_address`}</label>
                                <input value='kumar.s.fj@gmail.com' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Header as='h3'>{t`kina_bank_beneficiary_banck_details`}</Header>

                    <Grid columns={2}>
                        <Grid.Column>
                            <Form.Field >
                                <label>{t`kina_bank_beneficiary_account_no`}</label>
                                <input value='113681658' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_bank_name`}</label>
                                <input value='NATIONAL AUSTRALIA BANK LIMITED' disabled={true} />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_swift_bic`}</label>
                                <input value='NATAAU3303M' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_branch_code`}</label>
                                <input value='BSB/IBAN/Fedwire/Bank Codes' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Grid>
                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_branch_address`}</label>
                                <input value='Cairns Central' disabled={true} style={{ marginBottom: 5 }} />
                                <input value='1-21 McLeod Street' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Grid columns={2}>
                        <Grid.Column>
                            <Form.Field >
                                <label>{t`kina_bank_city`}</label>
                                <input value='CAIRNS' disabled={true} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_bank_name`}</label>
                                <Dropdown
                                    placeholder='Please Select One'
                                    fluid
                                    selection
                                    options={countryOptions}
                                    disabled={true}
                                    value={'AUSTRALIA'}
                                />
                            </Form.Field>
                            <Form.Field >
                                <label>{t`kina_bank_currency_amount`}</label>
                                <input value='123.99' disabled={true} />
                            </Form.Field>
                        </Grid.Column>

                        <Grid.Column>
                            <Form.Field>
                                <label>{t`kina_bank_select_currency`}</label>
                                <Dropdown
                                    placeholder='Please Select One'
                                    fluid
                                    selection
                                    options={currencyOptions}
                                    disabled={true}
                                    value={'AUD'}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{t`kina_bank_account_currency_amount`}</label>
                                <input value='300.00' disabled={true} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid>

                    <Button style={{ marginTop: 15 }} type='submit'>{t`btn_submit`}</Button>
                    <br />
                </Form>
            }
        </>
    )
}

export default TransferForm