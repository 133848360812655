import React, {useEffect, useState} from 'react'
import {Modal, Grid as GridS} from 'semantic-ui-react'
import {Box, Grid, Container, CircularProgress} from "@material-ui/core"
import helpers, {cancelToken} from "../../services/helpers";
import {useAuth0} from "../../react-auth0-wrapper";

const ProfileRow = ({label, value}) => {
    return (
        <>
            <Grid item={true} lg={6} dangerouslySetInnerHTML={{__html: label}} />
            <Grid item={true} xs={6}>{value}</Grid>
        </>
    );
};

const Details = (props) => {
    const id = props.id;
    const [transactionData, setTransactionData] = useState({});
    const [loading, setLoading] = useState(true);
    const { getIdToken } = useAuth0();

    useEffect(() => {
        transactionDetail();
    }, []);

    const transactionDetail = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .debitTransactionDetail(id, token, source)
            .then(response => {
                if(response.data.success) {
                    setLoading(false);
                    setTransactionData(response.data.data);
                } else {
                    props.setDataToast({ open: true, type: "error", message: "Something went wrong while getting data", timeAutoHide: 3000 })
                }
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    return (
        <React.Fragment>
            <Modal.Header>
                <GridS columns={3}>
                    <GridS.Row>
                        <GridS.Column>
                            Transaction Detail
                        </GridS.Column>
                    </GridS.Row>
                </GridS>
            </Modal.Header>
            <Modal.Content scrolling>
                <Container component="main" maxWidth="lg">
                    {loading ? (
                        <Box style={{textAlign: "center"}}>
                            <CircularProgress size={60} />
                        </Box>
                    ) :
                        <Box>
                            <Grid style={{paddingTop: '18px', fontSize: '22px'}} container>
                                <ProfileRow label={"Order ID:"} value={transactionData.id} />
                                <ProfileRow label={"User Name:"} value={transactionData.user_name} />
                                <ProfileRow label={"Type:"} value={transactionData.type} />
                                <ProfileRow label={"Amount:"} value={(transactionData.amount/100).toFixed(2)+' '+transactionData.currency} />
                                <ProfileRow label={"Status:"} value={transactionData.status} />
                                <ProfileRow label={"Settle Status:"} value={transactionData.settle_status} />
                                <ProfileRow label={"Error Code:"} value={transactionData.error_code} />
                                <ProfileRow label={"Error Message:"} value={transactionData.error_message} />
                                <ProfileRow label={"Transaction Reference:"} value={transactionData.transaction_reference} />
                                <ProfileRow label={"Payment Type:"} value={transactionData.payment_type_description} />
                                <ProfileRow label={"Masked Pan:"} value={transactionData.masked_pan} />
                                {transactionData.type === "fiatToToken" &&
                                    <>
                                        <ProfileRow label={"Wallet Address:"} value={transactionData.wallet_address} />
                                        <ProfileRow label={"Network:"} value={transactionData.network} />
                                        <ProfileRow label={"Token:"} value={transactionData.token} />
                                        <ProfileRow label={"Region:"} value={transactionData.region} />
                                    </>
                                }
                            </Grid>
                        </Box>
                    }
                </Container>
            </Modal.Content>
        </React.Fragment>
    )
};

export default Details
