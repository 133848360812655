import React, { useEffect, useRef, useState } from 'react';
import { Grid, Dropdown } from 'semantic-ui-react';
import Context from '../../contexts/TpTransactionsContext';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import { useAuth0 } from '../../react-auth0-wrapper';
import helpers, { cancelMessage, cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import SearchBar from '../common/SearchBar';
import ShowResults from '../common/ShowResults';
import List from './List';

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    },[value]);
    return ref.current;
}

const Provider = (props) => {
    const [transactions, setTransactions] = useState({ list: undefined, count: 0, status: STATUS_INIT });
    const [searchTerm, setSearchTerm] = useState('');
    const [status, setStatus] = useState("");
    const prevStatus = usePrevious(status);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { getIdToken, logout } = useAuth0();
    const SEARCH_URL = '/v3/admin/restricted/debit/transactions';
    const urlParams = { page: 1, limit: itemsPerPage, status: status };
    if(prevStatus !== status) {
        urlParams.uuid = searchTerm;
    }
    props.setNameTitleModule('debit_transactions');
    const resolved = (response) => {
        const { data, success } = response.data;
        const { transactionRecords, transactionCount } = data;
        if (success) {
            setTransactions({
                list: transactionRecords,
                count: transactionCount,
                status: (transactionRecords && transactionRecords.length && transactionCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const options = [
        { key: 0, text: 'All', value: "" },
        { key: 1, text: 'Initialized', value: "initialized" },
        { key: 2, text: 'Processing', value: "processing" },
        { key: 2, text: 'Complete', value: "complete" },
        { key: 2, text: 'Failed', value: "failed" }
    ];

    const rejected = (error) => {
        if (error.message !== cancelMessage) {
            setTransactions({
                list: null,
                count: 0,
                status: STATUS_ERROR,
                error,
            });
        }
    };

    const refreshList = (response) => {
        if (response.statusText === STATUS_LOADING) {
            setTransactions({
                list: -1,
                count: null,
                status: STATUS_LOADING
            });
        } else {
            setCurrentPage(1);
            resolved(response);
        }
    };

    const onStatusChange = (event, { value }) => {
        urlParams.uuid = searchTerm;
        setStatus(value);
    };

    useEffect(() => {
        refreshList({ statusText: STATUS_LOADING });
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(SEARCH_URL, urlParams, source, getIdToken())
            .then(resolved)
            .catch(rejected);
        return () => {
            helpers.cancel(promise, source);
        };
    }, [props, status]);

    if (transactions.error && transactions.error.response.status === 401) {
        return logout({ returnTo: window.location.origin });
    }
    return (
        <React.Fragment>
            <SearchTermContext.Provider value={{ searchTerm, setSearchTerm }}>
                <ShowResultsContext.Provider value={{ itemsPerPage, setItemsPerPage }}>
                    <PageContext.Provider value={{ currentPage, setCurrentPage }}>
                        <Context.Provider value={setTransactions}>

                            <Grid columns={3}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <SearchBar onSearchTermChange={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} searchBy={"uuid"} placeholder={"Search By userID..."} />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <div style={{ float: 'left', paddingLeft: 25 }}>
                                            Search By&nbsp;&nbsp;
                                            <Dropdown
                                                defaultValue={""}
                                                options={options}
                                                value={status}
                                                onChange={onStatusChange}
                                                selection
                                            />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <ShowResults onListRefresh={refreshList} onFetchError={rejected} listURL={SEARCH_URL} urlParams={urlParams} searchBy={"uuid"} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {
                                <List
                                    status={transactions.status}
                                    list={transactions.list}
                                    listURL={SEARCH_URL}
                                    totalCount={transactions.count}
                                    error={transactions.error}
                                    metaData={props.metaData}
                                    nameTitleModule={props.nameTitleModule}
                                    setNameTitleModule={props.setNameTitleModule}
                                    setDataToast={props.setDataToast}
                                    role_name={props.metaData.role_name}
                                />
                            }
                        </Context.Provider>
                    </PageContext.Provider>
                </ShowResultsContext.Provider>
            </SearchTermContext.Provider>
        </React.Fragment>
    );
};

export default Provider;
