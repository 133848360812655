import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Confirm, Divider, Grid, Modal, Placeholder, Segment } from 'semantic-ui-react'
import styled from 'styled-components'
import helpers, { cancelToken } from '../../services/helpers'
import DetailsLine from '../common/DetailsLine'
import TransactionRiskIcon from '../common/TransactionRiskIcon'
import TransactionStatusIcon from '../common/TransactionStatusIcon'
import {useAuth0} from "../../react-auth0-wrapper";

const Avatar = styled.div`
	background-image: url(data:image/png;base64,${(props) => props.photo});
                background-size: cover;
                background-position: center center;
                width: 140px;
                height: 140px;
                display: inline-block;
                vertical-align: top;
            `

const GridLabel = styled.h3`
                position: absolute;
                top: 0;
                left: 0;
                transform: translate3d(32px, -15px, 0);
                background-color: #fff;
                padding: 0 4px;
                font-size: 18px;
            `

const TransactionDetails = (props) => {
    const [
        txDetails,
        setTxDetails
    ] = useState({});
    const [riskDetails, setRiskDetails] = useState({riskScore: "", riskLevel: "", riskState: {}});
    const iconSize = "large";
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [userIBAN, setUserIBAN] = useState('');
    const { t } = useTranslation();
    const { getIdToken } = useAuth0();
    const {
        amountCRDT,
        amountFrom,
        amountProvision,
        amountTo,
        currencyFrom,
        currencyProvision,
        currencyTo,
        timestamp,
        txID,
        bfID,
        phone,
        nameMemberFrom,
        nameMemberTo,
        addressMemberFrom,
        addressMemberTo,
        additionalData,
    } = props.data;

    useEffect(() => {
        const token = getIdToken();
        const sourceDetails = cancelToken.source();
        const promiseDetails = helpers.auth0
            .getCashIoTransactionDetails(txID, token, sourceDetails)
            .then((response) => {
                setTxDetails(response.data.data);
                const details = response.data.data.details;
                for(let i = 0; i < details.length; i++) {
                    if(details[i].name === "IBAN (RAILSBANK)") {
                        setUserIBAN(details[i].value);
                    }
                }
                if(riskRelatedStuffShow(response.data.data.type)) {
                    const promiseDetails = helpers.auth0
                        .getRiskRatingDetails(txID, token, sourceDetails)
                        .then((response) => {
                            if(response.data.success) {
                                setRiskDetails(response.data.data)
                            }
                        })
                        .catch(console.error);

                    return () => {
                        helpers.cancel(promiseDetails, sourceDetails)
                    }
                }
            })
            .catch(console.error);

        return () => {
            helpers.cancel(promiseDetails, sourceDetails)
        }
    }, []);

    // useEffect(() => {
    //     const token = getIdToken();
    //     const sourceDetails = cancelToken.source();
    //     const promiseDetails = helpers.auth0
    //         .getRiskRatingDetails(txID, token, sourceDetails)
    //         .then((response) => {
    //             if(response.data.success) {
    //                 setRiskDetails(response.data.data)
    //             }
    //         })
    //         .catch(console.error);
    //
    //     return () => {
    //         helpers.cancel(promiseDetails, sourceDetails)
    //     }
    // }, []);

    const typeLabel = (type) => {
        switch(type) {
            case 1:
                return "Fiat to Token";
            case 2:
                return "Token to Fiat";
            case 3:
                return "Fiat Withdraw";
            case 4:
                return "Fiat Deposit";
            case 5:
                return "Fiat Deposit Return";
            case 6:
                return "CRDT P2P";
            case 7:
                return "Token to Token";
            default:
                return type;
        }
    };

    const stepPercentage = (step) => {
        if(step) {
            const stepArr = step.split("/");
            if(stepArr.length >= 2) {
                return (stepArr[0]/stepArr[1] * 100).toFixed(2)+"%";
            }
        }
        return step;
    };

    const displayFiat = (val) => {
        return (val/100).toFixed(2);
    };

    const riskDetailsIcons = (val) => {
        if(!val) {
            return (<CheckCircleIcon fontSize="small" className="text-success" />);
        }
        return (<BlockIcon fontSize="small" className="text-danger" />);
    };

    const actionClickStatus = (statusData) => {
        setNewStatus(statusData);
        setMessageModalConfirm(t('transaction_change_status_confirm', {txID:txID, status:t('transaction_status_' + additionalData.status), newStatus:t('transaction_status_'+ statusData)}))
        setShowModalConfirm(true);
    };

    const handleConfirm = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0.changeCashIoTransactionStatus(bfID, newStatus, token, source);
        props.setDataToast({ open: true, type: "info", message: t('transaction_change_status_success', {txID:txID, newStatus:t('transaction_status_'+ newStatus)}), timeAutoHide: 6000 });
        props.setShowModal(false);
        setShowModalConfirm(false);
    };

    const sortSteps = (obj) => {
        return Object.keys(obj).sort((a, b) => {
            const number1 = parseInt(a.replace(/[^0-9]/g, ""), 10);
            const number2 = parseInt(b.replace(/[^0-9]/g, ""), 10);
            return number1 === number2 ? 0 : number1 > number2 ? 1 : -1;
        }).reduce(function(result, key) {
            result[key] = obj[key];
            return result;
        }, {});
    };

    const riskRelatedStuffShow = (type) => {
        return type && (type === 3 || type === 4);
    };

    const getAccountFrom = () => {
        if(txDetails.type) {
            if(txDetails.type === 4) {
                return userIBAN;
            }
            return addressMemberFrom !== "0x0000000000000000000000000000000000000000" ? addressMemberFrom : "";
        }
        return "";
    };

    const getAccountTo = () => {
        if(txDetails.type) {
            if(txDetails.type === 3 || txDetails.type === 5) {
                return userIBAN;
            }
            return addressMemberTo !== "0x0000000000000000000000000000000000000000" ? addressMemberTo : "";
        }
        return "";
    };

    return (
        <React.Fragment>
            <Modal.Header>
                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column>
                            {t('transaction_details_title')}
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            {additionalData && (
                                <>
                                    {(additionalData.status === "in_progress" || additionalData.status === "needs_review") && (riskRelatedStuffShow(txDetails.type)) && (
                                        <>
                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Button onClick={() => actionClickStatus('approve')}>
                                                    <CheckCircleIcon fontSize="small" className="text-success">
                                                    </CheckCircleIcon>
                                                    {t('transaction_change_status_approve')}
                                        </Button>
                                                <Button onClick={() => actionClickStatus('cancel')}>
                                                    <BlockIcon fontSize="small" className="text-danger">
                                                    </BlockIcon>
                                                    {t('transaction_change_status_cancel')}
                                        </Button>
                                            </ButtonGroup>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid.Column>
                        <Confirm
                            header={t('popup_header_confirm_status')}
                            open={showModalConfirm}
                            content={messageModalConfirm}
                            cancelButton={t('popup_cancel')}
                            confirmButton={t('popup_ok')}
                            onOpen={() => setShowModalConfirm(true)}
                            onCancel={() => setShowModalConfirm(false)}
                            onConfirm={handleConfirm}
                            size='tiny'
                        />
                        {riskRelatedStuffShow(txDetails.type) &&
                            <Grid.Column textAlign='right'>
                                <div style={{ fontSize: 14, fontWeight: 500, float: 'right' }}><TransactionRiskIcon iconSize={iconSize} risk={additionalData ? additionalData.risk : -1} /></div>
                                <div style={{ fontSize: 14, fontWeight: 500 }}><TransactionStatusIcon iconSize={iconSize} status={additionalData ? additionalData.status : null} /></div>
                            </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
                <div style={{ fontSize: 14, fontWeight: 500 }}>ID: {txID}</div>
            </Modal.Header>
            <Modal.Content scrolling>
                {/*<Segment basic>*/}
                    {/*<Grid columns={2}>*/}
                        {/*<Grid.Column>*/}
                            {/*<h3>{props.data.sendingSubOrgName} {!!props.data.countryFrom ? (<small>({props.data.countryFrom})</small>) : ''} </h3>*/}
                            {/*<p>{props.data.addressSendingMTO}</p>*/}
                        {/*</Grid.Column>*/}
                        {/*<Grid.Column>*/}
                            {/*<h3>{props.data.receivingSubOrgName} {!!props.data.countryTo ? (<small>({props.data.countryTo})</small>) : ''} </h3>*/}
                            {/*<p>{props.data.addressReceivingMTO}</p>*/}
                        {/*</Grid.Column>*/}
                    {/*</Grid>*/}
                    {/*<Divider section />*/}
                {/*</Segment>*/}
                <Segment>
                    <Grid columns={2} relaxed='very'>
                        <Grid.Column>
                            <GridLabel>
                                {nameMemberFrom}
                            </GridLabel>
                            <p>
                                <strong>{getAccountFrom()}</strong>
                            </p>
                            {/*{txDetails.from && txDetails.from.photo.length ? (*/}
                                {/*<Avatar photo={txDetails.from.photo} />*/}
                            {/*) : (*/}
                                    {/*<Placeholder*/}
                                        {/*style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }}>*/}
                                        {/*<Placeholder.Image />*/}
                                    {/*</Placeholder>*/}
                                {/*)}*/}
                        </Grid.Column>
                        <Grid.Column>
                            <GridLabel>
                                {nameMemberTo}
                            </GridLabel>
                            <p>
                                <strong>{getAccountTo()}</strong>
                            </p>
                            {/*{txDetails.to && txDetails.to.photo.length ? (*/}
                                {/*<Avatar photo={txDetails.to.photo} />*/}
                            {/*) : (*/}
                                    {/*<Placeholder*/}
                                        {/*style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }}>*/}
                                        {/*<Placeholder.Image />*/}
                                    {/*</Placeholder>*/}
                                {/*)}*/}
                        </Grid.Column>
                    </Grid>
                    <Divider vertical>{t('transaction_details_to')}</Divider>
                </Segment>
                <Segment vertical>
                    <DetailsLine width={170}>
                        <span>{t('transaction_details_data_and_time')}</span>
                        <strong>{moment(timestamp).utc().format('L LTS')}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Amount sent</span>
                        <strong>{amountFrom && currencyFrom ? `${currencyFrom.toLowerCase() === "eur" ? displayFiat(amountFrom) : amountFrom} ${currencyFrom.toUpperCase()}` : '-'}</strong>
                    </DetailsLine>
                    {/*<DetailsLine width={170}>*/}
                        {/*<span>Fee</span>*/}
                        {/*<strong>*/}
                            {/*{amountProvision && currencyProvision ? `${amountProvision} ${currencyProvision}` : '-'}*/}
                        {/*</strong>*/}
                    {/*</DetailsLine>*/}
                    <DetailsLine width={170}>
                        <span>Amount received</span>
                        <strong>{amountTo && currencyTo ? `${currencyTo.toLowerCase() === "eur" ? displayFiat(amountTo) : amountTo} ${currencyTo.toUpperCase()}` : '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>{t('transaction_details_amount_crdt')}</span>
                        <strong>{amountCRDT}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Purpose of Transaction</span>
                        <strong>{props.data.reasonForSending || '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Source of Funds</span>
                        <strong>{props.data.sourceOfMoney || '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Status</span>
                        <strong>{txDetails.status || '-'} {txDetails.status === "ERROR" && `(${txDetails.errorMessage})`}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Type</span>
                        <strong>{typeLabel(txDetails.type) || '-'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Step</span>
                        <strong>{stepPercentage(txDetails.step) || '-'}</strong>
                    </DetailsLine>
                    <Divider section />
                    {txDetails.exchange &&
                        <>
                            <h1>Exchange</h1>
                            <table className="cashio-detail-table">
                                <tr>
                                    <th>Label</th>
                                    <th>Value</th>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>{moment(txDetails.exchange.createDate).utc().format('L LTS')}</td>
                                </tr>
                                <tr>
                                    <td>Amount Sent</td>
                                    <td>{txDetails.exchange.inCurrency === "EUR" ? txDetails.exchange.inAmount/100 : txDetails.exchange.inAmount} {txDetails.exchange.inCurrency}</td>
                                </tr>
                                <tr>
                                    <td>Amount Received</td>
                                    <td>{txDetails.exchange.outCurrency === "EUR" ? txDetails.exchange.outAmount/100 : txDetails.exchange.outAmount} {txDetails.exchange.outCurrency}</td>
                                </tr>
                                <tr>
                                    <td>Rate</td>
                                    <td>{txDetails.exchange.inCurrency === "EUR" ? txDetails.exchange.rate * 100 :txDetails.exchange.rate/100}</td>
                                </tr>
                                <tr>
                                    <td>Rate Source</td>
                                    <td>{txDetails.exchange.rateSource}</td>
                                </tr>
                            </table>
                            <Divider section />
                        </>
                    }
                    <h1>Steps</h1>
                    {
                        txDetails.steps && Object.keys(txDetails.steps).length > 0 &&
                        <table className="cashio-detail-table">
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                            </tr>
                            {Object.keys(sortSteps(txDetails.steps)).map((name, i) =>
                                <tr key={i}><td>{name}</td><td>{txDetails.steps[name]}</td></tr>
                            )}
                        </table>
                    }
                    <Divider section />
                    {riskDetails.riskLevel &&
                        <>
                            <h1>Risk Details</h1>
                            <DetailsLine width={170}>
                                <span>Risk Score</span>
                                <strong>{riskDetails.riskScore || '-'}</strong>
                            </DetailsLine>
                            <DetailsLine width={170}>
                                <span>Risk Level</span>
                                <strong>{riskDetails.riskLevel || '-'}</strong>
                            </DetailsLine>
                            {
                                riskDetails.riskState && Object.keys(riskDetails.riskState).length > 0 &&
                                <table className="cashio-detail-table">
                                    <tr>
                                        <th>Name</th>
                                        <th>Score</th>
                                        <th>Consider</th>
                                        <th>Reject</th>
                                    </tr>
                                    {Object.keys(riskDetails.riskState).map((name, i) =>
                                        <tr key={i}>
                                            <td>{name}</td><td>{riskDetails.riskState[name].score}</td>
                                            <td>{riskDetailsIcons(riskDetails.riskState[name].isConsider)}</td>
                                            <td>{riskDetailsIcons(riskDetails.riskState[name].isRejected)}</td>
                                        </tr>
                                    )}
                                </table>
                            }
                            <Divider section />
                        </>
                    }

                    <h1>Related Transactions</h1>
                    {
                        txDetails.relatedTransactions && Object.keys(txDetails.relatedTransactions).length > 0 &&
                        <table className="cashio-detail-table">
                            <tr>
                                <th>Name</th>
                                <th>Status</th>
                            </tr>
                            {Object.keys(txDetails.relatedTransactions).map((name, i) =>
                                <tr key={i}><td>{name}</td><td>{txDetails.relatedTransactions[name]}</td></tr>
                            )}
                        </table>
                    }
                    <Divider section />
                    <h1>Additional Data</h1>
                    {
                        txDetails.details && txDetails.details.length > 0 &&
                        <table className="cashio-detail-table">
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                            {txDetails.details.map(detail =>
                                <tr><td>{detail.name}</td><td>{detail.name === "FIAT" ? displayFiat(detail.value)+" EUR" : detail.value}</td></tr>
                            )}
                        </table>
                    }
                </Segment>
            </Modal.Content>
        </React.Fragment>
    )
}

export default TransactionDetails
