import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import helpers, { cancelToken, STATUS_LOADING } from '../../services/helpers';
import SearchTermContext from '../../contexts/SearchTermContext';
import {useAuth0} from "../../react-auth0-wrapper";


const SearchBar = (props) => {
  const [searchPromise, setSearchPromise] = useState({ promise: null, source: null });
  const [timeoutSearch, setTimeoutSearch] = useState(null);

  const {searchTerm, setSearchTerm} = useContext(SearchTermContext);
  const { getIdToken } = useAuth0();

  const {t} = useTranslation();
  const SEARCH_TIMEOUT = 1000;


  const searchBy = props.searchBy || "keyword";
  const placeholder = props.placeholder || t('searchbar_placeholder');

  useEffect(() => {
    return () => {
      helpers.cancel(searchPromise.promise, searchPromise.source);
    };
  }, []);

  const performSearch = (term) => {
    props.onSearchTermChange({ statusText: STATUS_LOADING });   // show loader

    if (searchPromise.source !== null) {
      helpers.cancel(searchPromise.promise, searchPromise.source);
    }
    const options = {...props.urlParams};
    if (term.length > 0) {
      Object.assign(options, { [searchBy]: `${term}` });
    }
    const source = cancelToken.source();
    const promise = helpers.auth0
          .search(props.listURL, options, source, getIdToken())
          .then(props.onSearchTermChange)
          .catch(props.onFetchError);
    setSearchPromise({ promise, source });
  };

  const onInputChange = (term) => {
    setSearchTerm(term);
    clearTimeout(timeoutSearch);

    if (term.length > 0) {
      setTimeoutSearch(setTimeout(() => { performSearch(term); }, SEARCH_TIMEOUT));
    } else {
      performSearch(term);
    }
  };

  return (
    <Grid.Column>
      <div className='ui input'>
        <input type='text' id='member-search' placeholder={placeholder} autoComplete='off'
          value={searchTerm}
          onChange={event => onInputChange(event.target.value)} />
      </div>
    </Grid.Column>
  );
};

export default SearchBar;
