import { isArray } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Grid, Icon, Loader, Message, Modal, Pagination, Segment, Table } from 'semantic-ui-react';
import PageContext from '../../contexts/PageContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import TransactionsContext from '../../contexts/TransactionsContext';
import helpers, { cancelToken, STATUS_ERROR, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_OK } from '../../services/helpers';
import ErrorMessage from '../common/ErrorMessage';
import PageSelect from '../common/PageSelect';
import TransactionRiskIcon from '../common/TransactionRiskIcon';
import TransactionStatusIcon from '../common/TransactionStatusIcon';
import TransactionDetails from './TransactionDetails';
import {useAuth0} from "../../react-auth0-wrapper";

const TransactionsList = (props) => {
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [listPromise, setListPromise] = useState({ promise: null, source: null });
    const setTransactions = useContext(TransactionsContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { itemsPerPage } = useContext(ShowResultsContext);
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const { t } = useTranslation();
    const { getIdToken } = useAuth0();
    const SEARCH_URLTemp = '/v3/mobile/everid/temporalRemittance/list';
    const urlParamsTemp = itemsPerPage;
    useEffect(() => {
        return () => {
            if (listPromise.source !== null) {
                helpers.cancel(listPromise.promise, listPromise.source);
            }
        }
    }, [listPromise]);

    if (props.status === STATUS_INIT) {
        return (
            <Segment>
                <Loader active size='large' inline='centered'>{t('list_message_loading_transactions')}</Loader>
            </Segment>
        );
    }

    if (props.status === STATUS_ERROR) {
        return <ErrorMessage error={props.error} />;
    }

    if (props.status === STATUS_NO_RESULTS) {
        return (
            <Message>
                <Message.Header>{t('list_message_no_results_title')}</Message.Header>
                <p>{t('list_message_no_results_text')}</p>
            </Message>
        );
    }

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='9'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>{t('list_message_loading_transactions')}</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );

    // pagination
    var pageNumbers = [];
    for (let x = 0; x < Math.ceil(props.totalCount / itemsPerPage); x++) {
        pageNumbers.push(x);
    }

    const resolved = (response) => {
        const { remittancesList, remittancesCount, success } = response.data;
        if (success === true) {
            setTransactions({
                list: remittancesList,
                count: remittancesCount,
                status: (remittancesList && remittancesList.length && remittancesCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
            // console.log("currentPage",currentPage);
            // if(currentPage==2){
            //     const source = cancelToken.source();
            //     const promise = helpers
            //         .searchTemp(SEARCH_URLTemp,urlParamsTemp, source)
            //         .then(response=>{
            //
            //             const documents=[];
            //             for (var i = 0; i < response.data.length; i++) {
            //                 const tempList ={
            //                     phone:response.data[i].phone,
            //                     addressMemberFrom:response.data[i].addressMemberFrom, addressMemberTo:"", addressReceivingMTO:"",
            //                     addressSendingMTO:"",amountCRDT:response.data[i].amountCRDT, amountFrom:response.data[i].amountFrom,
            //                     amountProvision:response.data[i].amountProvision, amountTo:response.data[i].amountTo,
            //                     countryFrom:response.data[i].countryFrom, countryTo:response.data[i].countryTo,
            //                     currencyFrom:response.data[i].currencyFrom, currencyProvision:response.data[i].currencyProvision,
            //                     currencyTo:response.data[i].currencyTo, reasonForSending:response.data[i].reasonForSending,
            //                     receivingSubOrgName:response.data[i].orgTo, sendingSubOrgName:response.data[i].orgFrom,
            //                     sourceOfMoney:response.data[i].sourceOfMoney, timestamp:response.data[i].timestamp, txID:response.data[i].id, bfID:"",
            //                     nameMemberFrom:response.data[i].nameMemberFrom+" "+response.data[i].surnameMemberFrom, nameMemberTo:response.data[i].name+" "+response.data[i].lastName,
            //                     additionalData:{
            //                         risk: "1.15",
            //                         status: "in_progress"
            //                       }
            //                 }
            //                 documents.push(tempList);
            //             }
            //             console.log("SecondList",documents)
            //             var c = [...documents, ...remittancesList]
            //             setTransactions({
            //                 list: c,
            //                 count: c.length,
            //                 status: (c && c.length > 0) ? STATUS_OK : STATUS_NO_RESULTS
            //             });
            //         })
            //         .catch(console.error);
            //     return () => {
            //         helpers.cancel(promise, source);
            //     };
            // }else{
            //     setTransactions({
            //         list: remittancesList,
            //         count: remittancesCount,
            //         status: (remittancesList && remittancesList.length && remittancesCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            //     });
            // }
           
        }
    };

    const handlePaginationChange = (event, { activePage }) => {
        setCurrentPage(activePage);
        setTransactions({
            list: -1,
            count: props.totalCount,
            status: STATUS_LOADING
        });

        if (listPromise.source !== null) {
            helpers.cancel(listPromise.promise, listPromise.source);
        }

        const options = { keyword: `${searchTerm}*`, page: activePage, limit: itemsPerPage, from: '', to: '', sort: 'timestamp', direction: "DESC" };
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(props.listURL, options, source, getIdToken())
            .then(resolved)
            .catch(console.error);
        setListPromise({ promise, source });
    };

    // modal: transaction details
    const onTransactionInfoClick = (event) => {
        event.preventDefault();
        if (event.target.classList.contains('info')) {
            const key = event.target.dataset.key.toLowerCase();
            const selectedTransactionData = props.list.filter(transaction => {
                return (transaction.txID + transaction.addressMemberTo).toLowerCase() === key;
            });
            setSelectedTransaction(selectedTransactionData[0]);
            setShowModal(true);
        }
    };

    const onCloseModal = () => {
        setShowModal(false);
        setSelectedTransaction(null);
    };

    const selectedCheckTransaction = (event) => {
        const keycheckInfo = event.currentTarget.dataset.key.toLowerCase();
        const selectedTransactionDatacheckInfo = props.list.filter(transaction => {
            return (transaction.txID + transaction.addressMemberTo).toLowerCase() === keycheckInfo;
        });
        let index = props.transactionSelected.indexOf(selectedTransactionDatacheckInfo[0]);

        if (index === -1) {
            props.setEnableButtonReport(false);
            props.setTransactionSelected(props.transactionSelected.concat(selectedTransactionDatacheckInfo[0]))
        }
        else {
            if (props.transactionSelected.length < 2) {
                props.setEnableButtonReport(true);
            }
            props.transactionSelected.splice(index, 1);
        }
    };

    const tableRows = (isArray(props.list) ? props.list : []).map(({ sendingSubOrgName, addressMemberFrom, addressReceivingMTO, addressSendingMTO, receivingSubOrgName, addressMemberTo, amountFrom, currencyFrom, timestamp, txID, additionalData, nameMemberFrom, nameMemberTo }) => {
        return <Table.Row key={txID}>
            <Table.Cell textAlign="center">
                {nameMemberFrom}
                {/*<Checkbox*/}
                    {/*onClick={selectedCheckTransaction}*/}
                    {/*style={{ marginBottom: -3, marginRight: 5 }}*/}
                    {/*data-key={txID + addressMemberTo}*/}
                    {/*label={nameMemberFrom} />*/}
            </Table.Cell>
            <Table.Cell title={addressMemberFrom !== "0x0000000000000000000000000000000000000000" && addressMemberFrom}>
                {addressMemberFrom !== "0x0000000000000000000000000000000000000000" && addressMemberFrom}
            </Table.Cell>
            <Table.Cell textAlign="center">{nameMemberTo}</Table.Cell>
            <Table.Cell title={addressMemberTo !== "0x0000000000000000000000000000000000000000" && addressMemberTo}>
                {addressMemberTo !== "0x0000000000000000000000000000000000000000" && addressMemberTo}
            </Table.Cell>
            <Table.Cell textAlign="right">{currencyFrom.toLowerCase() === "eur" ? amountFrom/100 : amountFrom} {currencyFrom.toUpperCase()}</Table.Cell>
            <Table.Cell textAlign="center">{moment(timestamp).utc().format('L LTS')}</Table.Cell>
            <Table.Cell textAlign="center">{(!additionalData.subtype || (additionalData.subtype && additionalData.subtype * 1 !== 4 && additionalData.subtype * 1 !== 5)) && <TransactionRiskIcon risk={additionalData ? additionalData.risk : -1} />}</Table.Cell>
            <Table.Cell textAlign="center">{(!additionalData.subtype || (additionalData.subtype && additionalData.subtype * 1 !== 4 && additionalData.subtype * 1 !== 5)) && <TransactionStatusIcon status={additionalData ? additionalData.status : null} />}</Table.Cell>
            <Table.Cell textAlign='center'>
                <Icon circular inverted name='info' color='blue' data-key={txID + addressMemberTo} className='icon-button' />
            </Table.Cell>
        </Table.Row>
    });

    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table celled fixed singleLine compact onClick={onTransactionInfoClick}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign="center">{t('transaction_list_sender_org')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" style={{width: '10%'}}>{t('transaction_list_sender')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{t('transaction_list_destination_org')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" style={{width: '10%'}}>{t('transaction_list_recipent')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" style={{width: '10%'}}>{t('transaction_list_amount')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center">{t('transaction_list_data_and_time')}</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={1}>Risk</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" style={{width: '8%'}}>Risk Status</Table.HeaderCell>
                            <Table.HeaderCell textAlign="center" width={1}>{t('transaction_list_details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {props.status === STATUS_LOADING ? loadingContent : tableRows}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='9' textAlign='center' style={{ position: 'relative' }}>
                                <Pagination
                                    activePage={currentPage}
                                    totalPages={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                    siblingRange={1}
                                    boundaryRange={1}
                                    ellipsisItem={undefined}
                                    firstItem={undefined}
                                    lastItem={undefined}
                                    prevItem={undefined}
                                    nextItem={undefined}
                                    disabled={pageNumbers.length === 1}
                                />
                                <PageSelect activePage={currentPage}
                                    pagesTotal={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Modal style={{ marginTop: '25px' }}
                    open={showModal}
                    onClose={onCloseModal}>
                    <TransactionDetails
                        transactionSelected={props.transactionSelected}
                        setTransactionSelected={props.setTransactionSelected}
                        setEnableButtonReport={props.setEnableButtonReport}
                        data={selectedTransaction}
                        setDataToast={props.setDataToast}
                        setShowModal={setShowModal} />
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default TransactionsList;
