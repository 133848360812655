import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {Button, Confirm, Dimmer, Divider, Dropdown, Grid, Icon, Image, Loader, Modal, Placeholder, Popup, Segment} from 'semantic-ui-react';
import styled from 'styled-components';
import helpers, { cancelToken } from '../../services/helpers';
import DetailsLine from '../common/DetailsLine';
import ModalConfirm from '../common/ModalConfirm';
import { useAuth0 } from '../../react-auth0-wrapper';
import MembersOrgSelect from './MembersOrgSelect';
import ModalKYCHistoric from '../common/ModalKYCHistoric';
import dateFormat from 'dateformat';
import { withRouter } from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const Avatar = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
`;

const Thumb = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const CenterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const KYC = styled.div`
  margin-top: 40px;
`;

const isJSON = str => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

async function openImage(token, address, filename, message, thumbnail, docType) {
    const newTab = window.open();
    if(docType === "identity") {
        const img = document.createElement('img');
        img.src = `data:image/png;base64,${thumbnail}`;
        newTab.document.body.appendChild(img);
    } else {
        const source = cancelToken.source();
        newTab.document.body.innerHTML = message;
        await helpers.auth0.previewDocument(token, address, filename, source).then((response) => {
            newTab.document.body.innerHTML = '';
            const img = document.createElement('img');
            img.src = `data:image/png;base64,${response.data.file}`;
            newTab.document.body.appendChild(img);
        });
    }
}

const MemberDetails = (props) => {
    const [documents, setDocuments] = useState([]);
    const [photoThumbnail, setPhotoThumbnail] = useState(null);
    const [allowPromote, setAllowPromote] = useState(true);
    const [organizacionSelected, setOrganizacionSelected] = useState("");
    const [filtered, setFiltered] = useState(props.metaData);
    const { t } = useTranslation();
    const { profile, wallet, account, idv } = props.data;
    const [idvStatuses, setIdvStatuses] = useState(idv);
    const activeOrg = window.ADMIN_SUB_ORGNAME;
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [showModalConfirmAddCredits, setShowModalConfirmAddCredits] = useState(false);
    const [messageModalConfirmAddCredits, setMessageModalConfirmAddCredits] = useState('');
    const [showModalConfirmKycStatus, setShowModalConfirmKycStatus] = useState(false);
    const [messageModalConfirmKycStatus, setMessageModalConfirmKycStatus] = useState('');
    const [kycStatusToBe, setKycStatusToBe] = useState('');
    const [loading, setLoading] = useState(null);
    const [showOTPModalConfirm, setShowOTPModalConfirm] = useState(false);
    const [memberStatus, setMemberStatus] = useState(props.data.profile.status);
    const [allowConsider, setAllowConsider] = useState(true);
    const [crdtValue, setCrdtValue] = useState({crdt:1});
    const [getbalance, setbalance] = useState({balance:0});
    const [sanctionedWallets, setSanctionedWallets] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [riskLevel, setRiskLevel] = useState("");
    const { getIdToken } = useAuth0();
    const [editPhone, setEditPhone] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(profile.phoneNumber);
    const [phoneCountryCode, setPhoneCountryCode] = useState(profile.countryCode);
    const [showPhoneModalConfirm, setShowPhoneModalConfirm] = useState(false);
    console.log("PROPS DATA",props.data);

    const KYC_REVIEW_STATUS = "NEED_REVIEW";
    const KYC_NOT_PROCESSED_STATUS = "NOT_PROCESSED";

    let reportKYC;
    if (isJSON(profile.watchlistReport)) {
        try {
            reportKYC = JSON.parse(profile.watchlistReport).breakdown;
        } catch (e) {
            reportKYC = false;
        }
    }

    const DocumentImages = (documents).map(({ filename, thumbnail, docType }) => {
        const token = getIdToken();
        return (
            <Popup
                key={filename}
                trigger={
                    <Thumb style={{ cursor: 'pointer' }}
                        className='thumb'
                        key={filename}
                        photo={thumbnail}
                        onClick={() => openImage(token, profile.uuid, filename, t('popup_loading'), thumbnail, docType)}
                    />
                }
                content={t('popup_open_in_new_window')}
                position='top center'
                inverted />
        );
    });

    useEffect(() => {
        loadProfile();
        loadDocuments();
        loadRiskRatingReport();
        loadSanctionedWalletList();
        loadWalletScores();
    }, []);

    const loadRiskRatingReport = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getRiskRatingReport(profile.uuid, token, source)
            .then(response=>{
                setRiskLevel(response.data.data.riskLevel);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadWalletScores = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getWalletsScore(profile.uuid, token, source)
            .then(response=>{
                if(response.data.success && response.data.data) {
                    setWallets(response.data.data);
                }
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadSanctionedWalletList = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getSanctionedWallets(profile.uuid, token, source)
            .then(response=>{
                setSanctionedWallets(response.data.data);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadProfile = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getProfile(token,profile.uuid, source)
            .then(response=>{
                setbalance({balance:response.data.accounts.balance});
                setPhotoThumbnail(response.data.photoThumbnail);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadDocuments = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getDocuments(token,profile.uuid, source)
            .then(resolveDocs)
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const resolveDocs = (response) => {
        const documents = response.data.documents;
        if(documents) {
            setDocuments(documents);
            loadIdentityDocuments(documents);
        } else {
            loadIdentityDocuments();
        }
    };

    const loadIdentityDocuments = (docs) => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getIdentityDocuments(profile.uuid, token, source)
            .then(response => {
                resolveIdentityDocs(response, docs)
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const resolveIdentityDocs = (response, docs) => {
        const identityDocuments = response.data.data;
        const totalDocs = identityDocuments.length;
        let docsArr = [];
        for(let i = 0; i < totalDocs; i++) {
            const currentDoc = identityDocuments[i];
            if(currentDoc.frontImage) {
                const frontDocObj = {};
                frontDocObj.filename = currentDoc.docType.type;
                frontDocObj.thumbnail = currentDoc.frontImage;
                frontDocObj.docType = "identity";
                docsArr.push(frontDocObj);
            }
            if(currentDoc.backImage) {
                const backDocObj = {};
                backDocObj.filename = currentDoc.docType.type;
                backDocObj.thumbnail = currentDoc.backImage;
                backDocObj.docType = "identity";
                docsArr.push(backDocObj);
            }
        }
        if(docs) {
            const docNewArr = [...docs, ...docsArr];
            setDocuments(docNewArr);
        } else {
            setDocuments(docsArr);
        }
    };

    const handleClear = (response) => {
        setMemberStatus(response.data.users.status);
        return `${t('popup_consider_status')}`;
    };

    const handleConfirmResolvePromoteToAgent = (response) => {
        return `${profile.firstName} ${profile.lastName} ${t('popup_promoted_to_agent')}`;
    };

    const handleConfirmResolveMemberRemovedFromOrg = (response) => {
        return `${profile.firstName} ${profile.lastName} has been removed from ${activeOrg}.`;
    };

    const capitalize = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const statusIcon = status => {
        switch (status) {
            case 'consider':
                return (
                    <Icon color='yellow' name='exclamation triangle' style={{ 'marginRight': '10px' }} />
                )
            case 'complete':
            case 'clear':
                return (
                    <Icon color='green' name='check circle' style={{ 'marginRight': '10px' }} />
                )
            default:
                return (
                    <Icon color='blue' name='question circle' style={{ 'marginRight': '10px' }} />
                )

        }
    }

    const handleConfirm = async () => {
        setLoading(true);
        const options = {
            memberAddressHex: props.data.account.address,
            orgName: organizacionSelected,
        };
        helpers.registerOrg(options).then(response => {
            props.setDataToast({ open: true, type: "info", message: `Member ${profile.firstName} ${profile.lastName} was sent to the organization ${organizacionSelected}`, timeAutoHide: 6000 });
            props.setShowModal(false);
            setShowModalConfirm(false);
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            props.setShowModal(false);
            props.setDataToast({ open: true, type: "warning", message: err.response.data.Message, timeAutoHide: 6000 });
            setShowModalConfirm(false);
        });
    };
    // const openConfirmAddCredits = () => {
    //     if(crdtValue.crdt < 1 || crdtValue.crdt > 1000){
    //         props.setDataToast({ open: true, type: "warning", message: "CRDT should be between 1 to 1000", timeAutoHide: 6000 });
    //     }else{
    //         setShowModalConfirmAddCredits(true);
    //         setMessageModalConfirmAddCredits(`Are you sure you want to add ${crdtValue.crdt} Credits to ${profile.firstName} ${profile.lastName}`)
    //     }
    //
    // }


    const openConfirmKycStatus = (status) => {
        setShowModalConfirmKycStatus(true);
        setKycStatusToBe(status);
        const msg = status === KYC_REVIEW_STATUS ?
            `Are you sure you want to mark ${profile.firstName} ${profile.lastName} as KYC member?` :
            `Are you sure you want to revert KYC status of ${profile.firstName} ${profile.lastName} to non-KYC?`;
        setMessageModalConfirmKycStatus(msg)
    };
    const kycClear = () => {
        setLoading(true);
        const token = getIdToken();
        const source = cancelToken.source(); //profile.uuid,
        helpers.auth0.kycStatusChange(profile.uuid, kycStatusToBe, token, source).then(response => {
            setShowModalConfirmKycStatus(false);
            setLoading(false);
            if(response.data.success) {
                const updatedIdv = idv;
                updatedIdv.idvStatusWatchlist = "clear";
                setIdvStatuses(updatedIdv);
                props.setDataToast({ open: true, type: "info", message: "Success", timeAutoHide: 3000 });
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setLoading(false);
            setShowModalConfirmKycStatus(false);
            const resp = JSON.parse(error.request.response);
            if(resp.code.message === "DOCUMENT_STATUS_NOT_IN_REVIEW") {
                window.alert("Document status are not in review. it means either user is already KYCed or didn't provide valid documents");
            } else {
                window.alert(resp.code.message);}
        });
    };
    const showDetail = () => {
        setLoading(true);
        const source = cancelToken.source();
        const promise = helpers
            .checkKYC(profile.uuid,profile.identityProviderValues.ScanReference, source)
            .then(response=>{
                setLoading(false);
                    props.setDataToast({ open: true, type: "info", message: "Success", timeAutoHide: 3000 });
            })
            .catch(error=>{
                setLoading(false);
                if (error.response) {
                    var myObject = JSON.parse(error.request.response);
                    if(myObject.error== undefined){
                        props.setDataToast({ open: true, type: "info", message: myObject.Message, timeAutoHide: 6000 });

                    }else{
                        props.setDataToast({ open: true, type: "info", message: myObject.error, timeAutoHide: 6000 });

                    }

                } else if (error.request) {
                    window.alert(error.request);
                } else {
                    window.alert(error.message);
                }
            });

    }
    // const handleConfirmAddCredits = () => {
    //     setLoading(true);
    //     const dataAddCredits = {
    //         targetAddress: [account.address],
    //         forceRetry: "true",
    //         amount: parseInt(crdtValue.crdt)
    //     };
    //     helpers.addCredits(dataAddCredits).then(response => {
    //         props.setDataToast({ open: true, type: "info", message: `${crdtValue.crdt} Credits were added to the wallet`, timeAutoHide: 6000 });
    //         setShowModalConfirmAddCredits(false);
    //         setLoading(false);
    //     }).catch(response => {
    //         setLoading(false);
    //         setShowModalConfirm(false);
    //     });
    // }
    const sendOTP = () => {
        setLoading(true);
        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0.sendOTP(profile.uuid, token, source).then(response => {
            setShowOTPModalConfirm(false);
            setLoading(false);
            if(response.data.success) {
                props.setDataToast({ open: true, type: "info", message: t('otp_send_suc'), timeAutoHide: 6000 });
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setLoading(false);
            setShowOTPModalConfirm(false);
            const resp = JSON.parse(error.request.response);
            window.alert(resp.message);
        });
    }
    const handlePhoneNumber = (number, country) => {
        const countryCode = country.dialCode;
        const noCountryCodePhone = number.replace(countryCode, "");
        setPhoneNumber(noCountryCodePhone);
        setPhoneCountryCode(countryCode);
    };
    const updatePhoneNumber = () => {
        setLoading(true);
        const token = getIdToken();
        const source = cancelToken.source();
        const data = {
            countryCode: phoneCountryCode,
            phoneNumber: phoneNumber
        };
        helpers.auth0.updatePhone(profile.uuid, data, source, token).then(response => {
            console.log(response);
            setShowPhoneModalConfirm(false);
            setLoading(false);
            if(response.data.success) {
                props.setDataToast({ open: true, type: "info", message: t('phone_update_suc'), timeAutoHide: 6000 });
                setEditPhone(false);
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setLoading(false);
            setShowPhoneModalConfirm(false);
            const resp = JSON.parse(error.request.response);
            window.alert(resp.message);
        });
    };
    // const newDate=dateFormat(profile.documentDateOfExpiry, "dd/mm/yyyy");
    return (
        <>
            <Modal.Header>
                <span>{profile.firstName} {profile.lastName}</span>
                <Button className="member-details-kyc-clear" onClick={() => openConfirmKycStatus(KYC_REVIEW_STATUS)}>{t('member_details_kyc_check')}</Button>
                <Button style={{position: 'absolute', right: '12.5rem', top: '0.85rem',marginRight: '20%',backgroundColor: '#7dbcfa', color: 'white' }}
                                        onClick={() => openConfirmKycStatus(KYC_NOT_PROCESSED_STATUS)}>
                                        {t('member_details_kyc_uncheck')}
                                        </Button>
                <ModalKYCHistoric /*idv={idv}*/ report={profile.watchListReport || ""} />
                <Confirm
                    header={t('popup_header_confirm_status')}
                    open={showModalConfirmKycStatus}
                    content={messageModalConfirmKycStatus}
                    cancelButton={t('popup_cancel')}
                    confirmButton={t('popup_ok')}
                    onOpen={() => setShowModalConfirmKycStatus(true)}
                    onCancel={() => setShowModalConfirmKycStatus(false)}
                    onConfirm={kycClear}
                    size='tiny'
                />
            </Modal.Header>
            <Modal.Content scrolling>
                <Segment vertical style={{ paddingTop: 0 }}>
                    {photoThumbnail ? (
                        <Avatar photo={photoThumbnail} />
                    ) : (
                            <Placeholder style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }} >
                                <Placeholder.Image />
                            </Placeholder>
                        )}
                    <div style={{ display: 'inline-block' }}>
                        <Segment basic compact>
                            <DetailsLine><span>{t('member_details_created_at')}</span><strong>{moment(profile.createdAt).utc().format('L LTS')}</strong></DetailsLine>
                            <DetailsLine><span>{t('member_details_ew_address')}</span><strong>{account.address}</strong></DetailsLine>
                            <DetailsLine><span>{t('member_details_no_of_tokens')}</span><strong>{getbalance.balance}</strong></DetailsLine>
                            <DetailsLine><span>{t('risk_rating')}</span><strong>{riskLevel}</strong></DetailsLine>
                        </Segment>
                    </div>
                </Segment>
                {allowPromote === true ? (
                    <Segment vertical>
                        <CenterButtons>
                            <ModalConfirm
                                trigger={<Button positive>{t('member_details_promote')}</Button>}
                                onAction={() => helpers.promote(account.address)}
                                onResolve={handleConfirmResolvePromoteToAgent}
                                onClose={() => {
                                    setAllowPromote(false);
                                    props.history.push("/agents");
                                }}
                            />
                            <Button style={{ backgroundColor: '#fa4261', color: 'white', marginLeft: '5px' }} onClick={() => setShowOTPModalConfirm(true)}>{t('member_send_otp')}</Button>
                            <Confirm
                                header={t('popup_header_confirm_status')}
                                open={showOTPModalConfirm}
                                content={t('otp_send_confirm')}
                                cancelButton={t('popup_cancel')}
                                confirmButton={t('popup_ok')}
                                onOpen={() => setShowOTPModalConfirm(true)}
                                onCancel={() => setShowOTPModalConfirm(false)}
                                onConfirm={sendOTP}
                                size='tiny'
                            />
                            {/*{props.role_name === 'SUPER_ADMIN' && (*/}
                                {/*<>*/}
                                    {/*<div className='ui input'>*/}
                                    {/*<span>CRDT:</span><input type='text' style={{ width: 110}} placeholder="1 to 1000" autoComplete='off'*/}
                                        {/*value={*/}
                                            {/*crdtValue.crdt*/}
                                        {/*}*/}
                                        {/*onChange={e =>{setCrdtValue({crdt:e.target.value})}} */}
                                        {/*/>*/}

                                        {/*<Button style={{ backgroundColor: '#fa4261', color: 'white' }}*/}
                                        {/*onClick={() => openConfirmAddCredits()}>*/}
                                        {/*{t('member_details_btn_add_credits')}*/}
                                        {/*</Button>*/}
                                    {/*</div>*/}
                                    {/**/}
                                    {/*<Confirm*/}
                                        {/*header={t('popup_header_confirm_status')}*/}
                                        {/*open={showModalConfirmAddCredits}*/}
                                        {/*content={messageModalConfirmAddCredits}*/}
                                        {/*cancelButton={t('popup_cancel')}*/}
                                        {/*confirmButton={t('popup_ok')}*/}
                                        {/*onOpen={() => setShowModalConfirmAddCredits(true)}*/}
                                        {/*onCancel={() => setShowModalConfirmAddCredits(false)}*/}
                                        {/*onConfirm={handleConfirmAddCredits}*/}
                                        {/*size='tiny'*/}
                                    {/*/>*/}
                                {/*</>*/}
                            {/*)}*/}
                            {!props.OECPRE_MODE && activeOrg === 'INF' && (
                                <>
                                    <Dropdown
                                        className="selection color-letter-dropdown-white"
                                        text="Change to Organization"
                                        style={{ backgroundColor: '#fa4261', color: 'white' }}>
                                        <Dropdown.Menu>
                                            <MembersOrgSelect
                                                onClick={evt => { evt.stopPropagation() }}
                                                metaData={filtered}
                                                currentOrg={props.currentOrg}
                                                setCurrentOrg={props.setCurrentOrg}
                                                setMessageModalConfirm={setMessageModalConfirm}
                                                setShowModalConfirm={setShowModalConfirm}
                                                style={{ backgroundColor: '#fa4261', color: 'white' }}
                                                setOrganizacionSelected={setOrganizacionSelected}
                                                data={props.data}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            )}
                            {!!activeOrg && activeOrg !== 'INF' &&
                                <ModalConfirm
                                    trigger={<Button>Remove from {activeOrg}</Button>}
                                    onAction={() => helpers.removeMemberFromOrg(account.address)}
                                    onResolve={handleConfirmResolveMemberRemovedFromOrg}
                                    onClose={() => setAllowPromote(false)}
                                />
                            }

                        </CenterButtons>
                    </Segment>
                ) : ('')}
                <Segment vertical>
                    <DetailsLine width={154}><span>E-mail</span><strong>{profile.email || 'N/A'}</strong></DetailsLine>
                    <DetailsLine width={154}><span>{t('member_details_address')}</span><strong>{profile.address || '-'}</strong></DetailsLine>
                    <DetailsLine width={'auto'} style={{'display': 'flex', 'justifyContent': 'flex-start', 'alignItems': 'center'}}>
                        <span style={{width: '154px'}}>{t('member_details_phone_number')}</span>
                        {editPhone ?
                            <>
                                <PhoneInput
                                    containerStyle={{width: '285px'}}
                                    inputStyle={{width: '100%'}}
                                    country={'us'}
                                    disableCountryCode={false}
                                    countryCodeEditable={false}
                                    autoFormat={false}
                                    value={phoneCountryCode+phoneNumber}
                                    onChange={(number, country) => handlePhoneNumber(number, country)}
                                    onKeyDown={(e) => e.key === 'Enter' && setShowPhoneModalConfirm(true)}
                                />
                                <Button style={{ backgroundColor: '#fa4261', color: 'white', marginLeft: '5px' }} onClick={() => setShowPhoneModalConfirm(true)}>
                                    {t('btn_update')}
                                </Button>

                                <Confirm
                                    header={t('popup_header_confirm_status')}
                                    open={showPhoneModalConfirm}
                                    content={t('phone_update_confirm')}
                                    cancelButton={t('popup_cancel')}
                                    confirmButton={t('popup_ok')}
                                    onOpen={() => setShowPhoneModalConfirm(true)}
                                    onCancel={() => setShowPhoneModalConfirm(false)}
                                    onConfirm={updatePhoneNumber}
                                    size='tiny'
                                />
                            </>:
                            <>
                                <strong>{phoneCountryCode && `(+${phoneCountryCode})`} {phoneNumber || 'N/A'}</strong>
                                <Icon onClick={() => setEditPhone(true)} color='blue' name='edit' style={{'marginLeft': '10px', 'marginTop': '-10px', 'cursor': 'pointer' }} />
                            </>
                        }
                    </DetailsLine>
                </Segment>
                <Segment vertical>
                    <DetailsLine width={154}><span>{t('member_details_sex')}</span><strong>{profile.gender}</strong></DetailsLine>
                    <DetailsLine width={154}><span>{t('member_details_birth_date')}</span><strong>{profile.birthDate}</strong></DetailsLine>
                    <DetailsLine width={154}><span>{t('member_details_nationality')}</span><strong>{profile.nationality}</strong></DetailsLine>
                </Segment>
                {wallets.length > 0 &&
                <>
                    <Segment basic>
                        <h3>Linked/Associated Wallets:</h3>
                        {wallets.map(sWallet =>
                            <>
                                <DetailsLine><span>Wallet Name</span><strong>{sWallet.name}</strong></DetailsLine>
                                <DetailsLine><span>Wallet Address</span><strong>{sWallet.address}</strong></DetailsLine>
                                <DetailsLine><span>Score</span><strong>{sWallet.score}</strong></DetailsLine>
                                <Divider section />
                            </>
                        )}
                    </Segment>
                </>
                }
                <Segment vertical>
                    <p><strong>{t('member_details_documents')}</strong></p>
                    {profile.identityDocuments && profile.identityDocuments.length > 0 ?
                        profile.identityDocuments.map(identityDoc =>
                            <>
                                <DetailsLine width={234}><span>{t('idv_type')}</span><strong>{identityDoc.documentType || 'N/A'}</strong></DetailsLine>
                                <DetailsLine width={234}><span>{t('idv_exp_date')}</span><strong>{identityDoc.documentDateOfExpiry}</strong></DetailsLine>
                                <hr align="left" style={{width: '50%', border: '1px solid #CCC'}} />
                            </>
                        ) :
                        <>
                            <DetailsLine width={234}><span>{t('idv_type')}</span><strong>{profile.documentType || 'N/A'}</strong></DetailsLine>
                            <DetailsLine width={234}><span>{t('idv_exp_date')}</span><strong>{profile.documentDateOfExpiry}</strong></DetailsLine>
                        </>
                    }

                    {(DocumentImages.length > 0) ? (
                        <Image.Group size='small'>
                            {DocumentImages}
                        </Image.Group>
                    ) : (
                            <Placeholder style={{ width: 140, height: 140, display: 'inline-block', verticalAlign: 'top' }} >
                                <Placeholder.Image />
                            </Placeholder>
                        )}
                </Segment>

                <Segment basic>
                    <p><strong>{t('member_details_dataKYC')}</strong></p>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column floated='left'>
                                <DetailsLine><span>{t('kyc_status_identity')}</span></DetailsLine>
                                <DetailsLine><span>{t('kyc_status_facial')}</span></DetailsLine>
                                <DetailsLine><span>{t('kyc_status_watchlist')}</span></DetailsLine>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <DetailsLine><strong>{statusIcon(idvStatuses.idvStatusIdentity || 'N/A')} {capitalize(idvStatuses.idvStatusIdentity || 'N/A')} </strong></DetailsLine>
                                <DetailsLine><strong>{statusIcon(idvStatuses.idvStatusFacial || 'N/A')} {capitalize(idvStatuses.idvStatusFacial || 'N/A')} </strong></DetailsLine>
                                <DetailsLine><strong>{statusIcon(idvStatuses.idvStatusWatchlist || 'N/A')} {capitalize(idvStatuses.idvStatusWatchlist || 'N/A')} </strong></DetailsLine>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                <Segment vertical>
                <p><strong>{t('kyc_report')}</strong>{!!reportKYC ? statusIcon('consider') : statusIcon('clear')}</p>
                {!!reportKYC ? (
                        <KYC>
                            <DetailsLine><span>Adverse Media:</span><strong>{statusIcon(reportKYC.adverse_media.result || 'N/A')}<span>{capitalize(reportKYC.adverse_media.result || 'N/A')}</span></strong></DetailsLine>
                            <DetailsLine><span>Sanction Lists:</span><strong>{statusIcon(reportKYC.sanction.result || 'N/A')}<span>{capitalize(reportKYC.sanction.result || 'N/A')}</span></strong></DetailsLine>
                            <DetailsLine><span>Politically Exposed Person:</span><strong>{statusIcon(reportKYC.politically_exposed_person.result || 'N/A')}<span>{capitalize(reportKYC.politically_exposed_person.result || 'N/A')}</span></strong></DetailsLine>
                        </KYC>
                    ) : <KYC><pre><a href={profile.watchlistReport}>{profile.watchlistReport}</a></pre></KYC>}
                </Segment>

                {sanctionedWallets.length > 0 &&
                <>
                    <Segment basic>
                        <h3>Sanctioned Wallets:</h3>
                        {sanctionedWallets.map(sWallet =>
                            <>
                                <DetailsLine><span>Wallet Address</span><strong>{sWallet.walletAddress}</strong></DetailsLine>
                                <DetailsLine><span>Score</span><strong>{sWallet.score}</strong></DetailsLine>
                                <DetailsLine><span>Details</span><strong><a href={sWallet.detailsURL} target="_blank">{sWallet.detailsURL}</a></strong></DetailsLine>
                                <DetailsLine><span>Created</span><strong>{moment(sWallet.createdAt).utc().format('L LTS')}</strong></DetailsLine>
                                <Divider section />
                            </>
                        )}
                    </Segment>
                </>
                }
                <Segment basic>
                    <p><u><strong>Additional User Data:</strong></u></p>
                    <DetailsLine><span>Country of Residence</span><strong>{profile.residentialAddress.country || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Nationality</span><strong>{profile.nationality || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Place of Birth</span><strong>{profile.birthPlace || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Employer</span><strong>{profile.employer || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Profession</span><strong>{profile.profession || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Occupation</span><strong>{profile.occupation || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>User Declared PEP</span><strong>{profile.politicalExposure.isExposed ? profile.politicalExposure.exposed : 'NO'}</strong></DetailsLine>
                    <DetailsLine><span>Source of Wealth</span><strong>{profile.customerDueDiligence.source || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Origin of Funds</span><strong>{profile.customerDueDiligence.originFunds || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Expected Frequency</span><strong>{profile.customerDueDiligence.frequency || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Reason for Crypto</span><strong>{profile.customerDueDiligence.reason || 'N/A'}</strong></DetailsLine>
                </Segment>

                {(loading === true) ? (
                    <Dimmer active={true} page>
                        <Loader size='huge'>{t('popup_loading')}</Loader>
                    </Dimmer>
                ) : ""}
                <Confirm
                    header={t('popup_header_confirm_status')}
                    open={showModalConfirm}
                    content={messageModalConfirm}
                    cancelButton={t('popup_cancel')}
                    confirmButton={t('popup_ok')}
                    onOpen={() => setShowModalConfirm(true)}
                    onCancel={() => setShowModalConfirm(false)}
                    onConfirm={handleConfirm}
                    size='tiny'
                />
            </Modal.Content>
        </>
    );
};

export default withRouter(MemberDetails);
