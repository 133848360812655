import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal, Message, Pagination, Table, Grid, Segment, Loader } from 'semantic-ui-react';
import helpers, { cancelToken, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_ERROR, STATUS_OK } from '../../services/helpers';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import TransactionContext from '../../contexts/TpTransactionsContext';
import PageContext from '../../contexts/PageContext';
import Details from './Details';
import PageSelect from '../common/PageSelect';
import ErrorMessage from '../common/ErrorMessage';
import {useAuth0} from "../../react-auth0-wrapper";


const MembersList = (props) => {
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [listPromise, setListPromise] = useState({ promise: null, source: null });

    const setTransactions = useContext(TransactionContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { itemsPerPage } = useContext(ShowResultsContext);
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const { getIdToken } = useAuth0();

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            if (listPromise.source !== null) {
                helpers.cancel(listPromise.promise, listPromise.source);
            }
        }
    }, [listPromise]);

    if (props.status === STATUS_INIT) {
        return (
            <Segment>
                <Loader active size='large' inline='centered'>{t('list_message_loading_transactions')}</Loader>
            </Segment>
        );
    }

    if (props.status === STATUS_ERROR) {
        return <ErrorMessage error={props.error} />;
    }

    if (props.status === STATUS_NO_RESULTS) {
        return (
            <Message>
                <Message.Header>{t('list_message_no_results_title')}</Message.Header>
                <p>{t('list_message_no_results_text')}</p>
            </Message>);
    }

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='8'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>{t('list_message_loading_transactions')}</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );


    // pagination
    const pageNumbers = [];
    for (let x = 0; x < Math.ceil(props.totalCount / itemsPerPage); x++) {
        pageNumbers.push(x);
    }

    const resolved = (response) => {
        const { data, success } = response.data;
        const { transactionRecords, transactionCount } = data;
        if (success === true) {
            setTransactions({
                list: transactionRecords,
                count: transactionCount,
                status: (transactionRecords && transactionRecords.length && transactionCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const handlePaginationChange = (event, { activePage }) => {
        setCurrentPage(activePage);
        setTransactions({
            list: -1,
            count: props.totalCount,
            status: STATUS_LOADING
        });

        if (listPromise.source !== null) {
            helpers.cancel(listPromise.promise, listPromise.source);
        }

        const options = { uuid: `${searchTerm}`, page: activePage, limit: itemsPerPage };
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(props.listURL, options, source, getIdToken())
            .then(resolved)
            .catch(console.error);
        setListPromise({ promise, source });
    };

     
    // modal: member details
    const onMemberInfoClick = (id) => {
        setSelectedTransaction(id);
        setShowModal(true);
    };

    const onCloseModal = () => {
        setShowModal(false);
        setSelectedTransaction(null);
    };

    const tableRows = props.list.length > 0 && props.list.map((trans) => {
        return (
            <Table.Row key={trans.id}>
                <Table.Cell>{trans.id}</Table.Cell>
                <Table.Cell>{trans.created_at}</Table.Cell>
                <Table.Cell>{trans.user_id}</Table.Cell>
                <Table.Cell>{trans.type}</Table.Cell>
                <Table.Cell>{(trans.amount/100).toFixed(2)+' '+trans.currency}</Table.Cell>
                <Table.Cell>{trans.status}</Table.Cell>
                <Table.Cell textAlign='center' onClick={() => onMemberInfoClick(trans.id)}>
                    <Icon circular inverted name='info' color='blue' data-key={trans.id} className='icon-button' />
                </Table.Cell>
            </Table.Row>
        );
    });

    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table sortable celled singleLine compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ width: 200 }}>ID</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Created</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>User ID</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Type</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Amount</Table.HeaderCell>
                            <Table.HeaderCell style={{ width: 200 }}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>{t('member_list_details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(props.status === STATUS_LOADING) ? loadingContent : tableRows}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6' textAlign='center' style={{ position: 'relative' }}>
                                <Pagination
                                    activePage={currentPage}
                                    totalPages={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                    siblingRange={1}
                                    boundaryRange={1}
                                    disabled={pageNumbers.length === 1}
                                />
                                <PageSelect activePage={currentPage}
                                    pagesTotal={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Modal open={showModal} onClose={onCloseModal} style={{width: "60%"}}>
                    <Details
                        id={selectedTransaction}
                        setShowModal={setShowModal}
                        setDataToast={props.setDataToast}
                    />
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default MembersList;
